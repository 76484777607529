import { AI_AGENTS } from 'shared/consts/QAConsts';

function Agents({ className, size, fillColor }: { className?: string; size?: number; fillColor?: string }) {
	return (
		<svg
			width={size || 40}
			height={size || 40}
			viewBox='0 0 40 40'
			fill='none'
			className={className}
			data-qa={AI_AGENTS}
			xmlns='http://www.w3.org/2000/svg'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
			style={{ paddingTop: '0.65rem', paddingLeft: '0.75rem' }}
		>
			<g opacity='inherit'>
				<path d='M12 5a3 3 0 1 0-5.997.125 4 4 0 0 0-2.526 5.77 4 4 0 0 0 .556 6.588A4 4 0 1 0 12 18Z' />
				<path d='M12 5a3 3 0 1 1 5.997.125 4 4 0 0 1 2.526 5.77 4 4 0 0 1-.556 6.588A4 4 0 1 1 12 18Z' />
				<path d='M15 13a4.5 4.5 0 0 1-3-4 4.5 4.5 0 0 1-3 4' />
				<path d='M17.599 6.5a3 3 0 0 0 .399-1.375' />
				<path d='M6.003 5.125A3 3 0 0 0 6.401 6.5' />
				<path d='M3.477 10.896a4 4 0 0 1 .585-.396' />
				<path d='M19.938 10.5a4 4 0 0 1 .585.396' />
				<path d='M6 18a4 4 0 0 1-1.967-.516' />
				<path d='M19.967 17.484A4 4 0 0 1 18 18' />
			</g>
		</svg>
	);
}

export default Agents;
